/* Comparison Section */
.salary-comparison-section {
  margin-top: 60px;
  margin-bottom: 100px;
}

.salary-comparison-section .salary-comparison-wrapper {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
}

.salary-comparison-section .comparison-heading {
  font-size: 40px;
  font-weight: 700;
  color: #f2ebbf;
  margin-right: 20px;
  text-align: right;
}

.salary-comparison-section .comparison-subtitle {
  font-size: 16px;
  font-weight: 500;
  color: #f8eee3;
  margin: 10px 0;
  margin-right: 20px;
  text-align: right;
  margin-bottom: 30px;
}

.salary-comparison-section .comparison-widgets-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.salary-comparison-section .comparison-widgets-wrapper.visible {
  opacity: 1;
}

.salary-comparison-section .typography-subtitle {
  font-size: 18px;
  text-align: center;
  margin-bottom: 16px;
  color: #f8eee3;
}

.salary-comparison-section .slider-container {
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
}

.salary-comparison-section .slider-label {
  font-size: 18px;
  margin-bottom: 5px;
}

.salary-comparison-section .dropdown-container {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.salary-comparison-section .dropdown {
  max-width: 200px;
  margin: 10px auto;
  padding: 10px;
  border-radius: 5px;
}

.salary-comparison-section .bubble-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.salary-comparison-section .bubble-selector {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}

.salary-comparison-section .button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.salary-comparison-section .progress-bar {
  height: 8px;
  margin-bottom: 20px;
  width: 100%;
  border-radius: 20px; /* Added border radius */
}

.salary-comparison-section .compare-button-container {
  display: flex;
  justify-content: center; /* Center the button horizontally */
  margin-top: 20px;
}

.salary-comparison-section .compare-button {
  background: linear-gradient(145deg, #003333, #005555);
  border: 1px solid #f2ebbf;
  color: #f2ebbf;
  padding: 10px 30px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 15px;
  transition:
    all 0.4s ease,
    transform 0.4s ease,
    opacity 0.4s ease,
    background-position 0.4s ease;
  box-shadow: 0 10px 20px rgba(0, 0, 64, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-size: 200% 200%;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  margin-top: 20px;
}

.salary-comparison-section .compare-button:hover {
  background: linear-gradient(145deg, #005555, #003333);
  background-position: right center;
  color: #f2ebbf;
  transform: translateY(-3px);
  box-shadow:
    0 15px 30px rgba(0, 0, 64, 0.4),
    0 0 10px rgba(0, 150, 150, 0.5);
  opacity: 0.9;
  border-color: rgba(0, 150, 150, 0.7);
}

.salary-comparison-section .arrow-left::before {
  content: "\2190"; /* Left arrow Unicode */
  margin-right: 8px;
}

.salary-comparison-section .arrow-right::after {
  content: "\2192"; /* Right arrow Unicode */
  margin-left: 8px;
}

/* Media Queries */
@media (max-width: 768px) {
  .salary-comparison-section .comparison-widgets-wrapper {
    flex-direction: column;
    align-items: center;
  }
}

.salary-comparison-section .disclaimer {
  text-align: center;
  width: 100%;
  margin-top: 20px;
  color: #a0a0a0;
  font-size: 12px;
}
