/* Simple CSS Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  font-family: "Montserrat", sans-serif;
  height: 100%;
  background-color: #004040;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* Container Styles */
.landing-page-container {
  background-color: #004040;
  color: #f8eee3;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
}

/* Header Styles */
.landing-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: rgba(0, 64, 64, 0.9);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  z-index: 10;
}

.logo-wrapper {
  display: flex;
  align-items: center;
}

.logo {
  height: 40px;
  margin-right: 10px;
}

.peek-button {
  background: linear-gradient(145deg, #003333, #005555);
  border: 1px solid #f2ebbf;
  color: #f2ebbf;
  padding: 10px 30px;
  font-size: 18px;
  cursor: pointer;
  border-radius: 10px;
  transition:
    all 0.4s ease,
    transform 0.4s ease,
    opacity 0.4s ease,
    background-position 0.4s ease;
  box-shadow: 0 10px 20px rgba(0, 0, 64, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-size: 200% 200%;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.peek-button:hover {
  background: linear-gradient(145deg, #005555, #003333);
  background-position: right center;
  color: #f2ebbf;
  transform: translateY(-3px);
  box-shadow:
    0 15px 30px rgba(0, 0, 64, 0.4),
    0 0 10px rgba(0, 150, 150, 0.5);
  opacity: 0.9;
  border-color: rgba(0, 150, 150, 0.7);
}

/* Main Content Styles */
.main-content {
  text-align: center;
  padding-top: 100px;
  width: 100%;
}

.main-heading {
  font-size: 60px;
  font-weight: 700;
  color: #f2ebbf;
}

.main-subtitle {
  font-size: 17px;
  font-weight: 500;
  color: #f8eee3;
  margin: 20px 0;
}

.button-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.main-peek-button {
  margin-top: 20px;
  border-radius: 10px;
}

.fade-in-slide {
  opacity: 0;
  transform: translateY(20px);
}

.animate {
  animation: fadeInSlide 0.5s ease-out forwards;
}

@keyframes fadeInSlide {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Animation Delays */
.fade-in-slide-delay-1 {
  animation-delay: 0.1s;
}

.fade-in-slide-delay-2 {
  animation-delay: 0.3s;
}

.fade-in-slide-delay-3 {
  animation-delay: 0.5s;
}

.fade-in-slide-delay-4 {
  animation-delay: 0.7s;
}

.fade-in-slide-delay-5 {
  animation-delay: 1.1s;
}

.fade-in-slide-delay-6 {
  animation-delay: 1.4s;
}

/* Image Styles */
.images-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.image-container {
  position: relative;
  max-width: 350px;
}

.blank-phone {
  width: 100%;
}

.post-image {
  position: absolute;
  width: 75%;
}

.post-1 {
  top: 10%;
  left: 45%;
}

.post-2 {
  top: 30%;
  left: -35%;
}

.post-3 {
  top: 70%;
  left: 50%;
}

/* Transparency Section */
.transparency-section-wrapper {
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.transparency-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 40px;
  box-sizing: border-box;
  max-width: 1200px;
  gap: 120px;
}

.text-container {
  flex: 1;
}

.section-heading {
  font-size: 50px;
  font-weight: 700;
  color: #f2ebbf;
  margin: 0;
  text-align: left;
}

.section-subtitle {
  font-size: 16px;
  font-weight: 500;
  color: #f8eee3;
  margin: 10px 0;
  text-align: left;
}

.transparency-images-wrapper {
  flex: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.image-container {
  position: relative;
  max-width: 400px; /* Adjust this width as needed */
  width: 100%;
}

.blank-phone-2 {
  width: 100%;
}

.widget-image {
  position: absolute;
  width: 53%;
}

.widget-1 {
  top: 190px;
  left: 35%;
}

.widget-2 {
  top: 320px;
  left: -15%;
}

.widget-3 {
  top: 450px;
  left: 45%;
}

/* Media Queries */
@media (max-width: 768px) {
  .comparison-widgets-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .transparency-section-wrapper {
    padding-top: 80px;
  }

  .transparency-section {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0 20px;
  }

  .landing-header {
    padding: 10px 20px;
  }

  .logo {
    height: 32px;
  }

  .blank-phone {
    width: 290px;
  }

  .post-image {
    width: 230px;
  }

  .post-1 {
    top: 70px;
    left: 140px;
  }

  .post-2 {
    top: 230px;
    left: 20px;
  }

  .post-3 {
    top: 450px;
    left: 140px;
  }

  .main-heading {
    font-size: 50px;
    padding: 0 20px;
  }

  .main-subtitle {
    font-size: 19px;
    padding: 0 20px;
  }

  .text-container {
    margin-bottom: 20px;
    padding: 0 20px;
    width: 100%;
  }

  .section-heading {
    font-size: 40px;
    padding: 0 20px;
  }

  .section-subtitle {
    font-size: 15px;
    padding: 0 20px;
  }

  .transparency-images-wrapper {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .blank-phone-2 {
    width: 350px;
    margin-top: -200px;
    margin-left: 50px;
  }

  .widget-image {
    width: 205px;
  }

  .widget-1 {
    top: 0px;
    left: 140px;
  }

  .widget-2 {
    top: 130px;
    left: 0px;
  }

  .widget-3 {
    top: 280px;
    left: 140px;
  }
}
